import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/pro-solid-svg-icons";

import "../../styles/learning.scss";

export const LearningTopics = () => {
    return (
        <>
            <h1 className="pb-3 display-1 text-center">Centre d'apprentissage</h1>
            <p className="py-3">
                Le centre d'apprentissage AuroraMAX fournit des informations sur la
                science des aurores et sur l'observatoire AuroraMAX, ainsi que des
                conseils sur la façon d'observer et de photographier les aurores par
                vous-même.
            </p>

            <Row>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/what-are-northern-lights">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Que sont les aurores boréales ?</h3>
                    </a>
                    <p>
                        Informations sur les aurores et le rôle du Soleil dans leur formation.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/colours-of-northern-lights">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Les couleurs des aurores boréales</h3>
                    </a>
                    <p>
                        Les facteurs qui déterminent les différentes couleurs des aurores.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/auroramax-observatory">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Observatoire AuroraMAX</h3>
                    </a>
                    <p>
                        Observatoire en ligne pour observer les aurores boréales du Canada en haute définition, en temps réel ou sur vidéo.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/aurora-viewing-tips">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Conseils pour l'observation des aurores</h3>
                    </a>
                    <p>
                        Quelques conseils sur la façon d'observer les aurores boréales en personne ou même dans le confort de votre maison.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/photographing-aurora">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Photographier les aurores</h3>
                    </a>
                    <p>
                        Équipement et réglages recommandés pour prendre de superbes photos des aurores boréales.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/studying-aurora-in-canada">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Étudier les aurores au Canada</h3>
                    </a>
                    <p>
                        Recherche canadienne sur l'ionosphère, la magnétosphère et les processus de la météo spatiale.
                    </p>
                </Col>
                {/* <Col xs={12} md={4} className="topic">
                    <a href="/learning/photos"><h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Photos</h3></a>
                    <p>
                        Auroras photographed with the cameras of the AuroraMAX observatory.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/videos"><h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Videos</h3></a>
                    <p>
                        Video archives of the northern lights, including videos captured by the AuroraMAX observatory.
                    </p>
                </Col> */}
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/auroramax-simulator">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Simulateur AuroraMAX</h3>
                    </a>
                    <p>
                        Exposition interactive à Yellowknife sur les aurores boréales.
                    </p>
                </Col>
            </Row>
        </>
    );
};

const Learning = () => {
    return (
        <Container fluid="sm" className="container-learning white-text pb-5">
            <Outlet />
        </Container>
    );
};

export default Learning;
