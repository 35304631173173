import React, { useEffect, useState } from 'react';
import {
  // Alert,
  Container,
  Dropdown,
  Button,
  ButtonGroup,
} from 'react-bootstrap';

const AURORAMAX_SITES = {
  yknf: 'Yellowknife',
};

let timer;

const LiveFeed = () => {
  const [selectedSite, setSelectedSite] = useState('yknf');
  const [resolution, setResolution] = useState(480);

  const updateUrl = (resolution) => {
    const dateNow = Date.now();
    return `https://auroramax.phys.ucalgary.ca/recent/recent_${resolution}p.jpg?${dateNow}`;
  };
  const [liveFeedUrl, setLiveFeedUrl] = useState(updateUrl(resolution));

  useEffect(() => {
    // update the image
    const newUrl = updateUrl(resolution);
    setLiveFeedUrl(newUrl);

    // clear the previous interval and set a new one
    clearInterval(timer);
    timer = setInterval(() => {
      const newUrl = updateUrl(resolution);
      setLiveFeedUrl(newUrl);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [resolution]);

  const blurThis = (e) => {
    e.target.blur();
  };

  const handleSiteSelection = (key, e) => {
    blurThis(e);
    setSelectedSite(key);
  };

  const handleResolutionSelection = (e, value) => {
    blurThis(e);
    setResolution(value);
  };

  return (
    <>
      <Container fluid className='text-center white-text pb-5'>
        {/* Alert */}
        {/*
        <div style={{ paddingBottom: '10px' }}>
          <Alert variant='info'>
            2024-08-09 : Nous dépoussiérons le flux en direct d'AuroraMAX de ses
            vacances d'été et le préparons pour une merveilleuse saison
            d'observation des aurores ! Nous prévoyons que la diffusion
            commencera dans les prochaines semaines.
          </Alert>
        </div>
        */}

        {/* Header */}
        <h1 className='pb-3 display-1'>Vidéo en direct</h1>
        <div className='row justify-content-center pb-3 px-4'>
          <div className='col-xs-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 col-xxl-5'>
            <p style={{ textAlign: 'justify' }}>
              Le projet AuroraMAX fournit une vidéo en direct et en temps réel
              des aurores du Nord canadien. Les images sont mises à jour environ
              toutes les 6 secondes pendant les heures de nuit. Veuillez noter
              que la caméra ne fonctionne pas les mois d'été (mai à août), car
              les nuits sont trop claires pour voir les aurores pendant cette
              période.
            </p>
          </div>
        </div>

        {/* Site selection */}
        <div style={{ display: 'inline-block', paddingBottom: '10px' }}>
          Location:
          <Dropdown
            onSelect={handleSiteSelection}
            onClick={blurThis}
            onMouseOut={blurThis}
            className='d-inline-block live-feed-location-dropdown'
            style={{ paddingLeft: '10px', paddingRight: '20px' }}
          >
            <Dropdown.Toggle variant='outline-light' style={{ width: '125px' }}>
              <span style={{ float: 'left' }}>
                {AURORAMAX_SITES[selectedSite]}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu variant='light'>
              <Dropdown.Item eventKey='yknf' active={selectedSite === 'yknf'}>
                Yellowknife
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Resolution selection */}
        <div style={{ display: 'inline-block', paddingBottom: '10px' }}>
          Resolution:
          <ButtonGroup style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <Button
              variant='outline-light'
              active={resolution === 480}
              onClick={(e) => handleResolutionSelection(e, 480)}
            >
              480p
            </Button>
            <Button
              variant='outline-light'
              active={resolution === 720}
              onClick={(e) => handleResolutionSelection(e, 720)}
            >
              720p
            </Button>
            <Button
              variant='outline-light'
              active={resolution === 1080}
              onClick={(e) => handleResolutionSelection(e, 1080)}
            >
              1080p
            </Button>
          </ButtonGroup>
        </div>

        {/* Live feed image */}
        <div className='pt-4'>
          <img className='img-fluid' src={liveFeedUrl} alt='latest live feed' />
        </div>

        {/* Info content */}
        <div className='row justify-content-center pb-3 px-4 pt-4'>
          <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-8 col-xxl-6'>
            <h1 className='display-6 pt-3 pb-3'>
              Pourquoi les images d'AuroraMAX sont-elles rondes?
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Imaginez que vous vous allongez sur le dos et que vous pouvez voir
              tout ce qui vous entoure. C'est ce que vous montre AuroraMAX. La
              caméra d'AuroraMAX est un imageur de tout le ciel. Elle capture un
              champ de vision de 180 degrés, ce qui produit des images
              circulaires qui montrent l'ensemble du ciel nocturne. La
              circonférence du cercle correspond à l'horizon tout autour de la
              caméra. Si vous regardez attentivement, la cime des arbres est
              souvent visible dans la partie extérieure de l'image.
            </p>

            <h1 className='display-6 pt-3 pb-3'>Spécifications techniques</h1>
            <ul style={{ textAlign: 'left', display: 'inline-block' }}>
              <li>Lieu : Yellowknife (T.N-O.)</li>
              <li>Longitude : 114°21'O</li>
              <li>Latitude : 62°26'N</li>
              <li>Appareil photo : Imageur couleur à ciel ouvert</li>
              <li>Objectif : ultra-grand angulaire (180°)</li>
              <li>Ouverture : f/4.0</li>
              <li>Durée d'exposition : 4 secondes</li>
              <li>Rafraichissement de l'image : 6 secondes</li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LiveFeed;
