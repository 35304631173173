import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/pro-solid-svg-icons";

export const TopicWhatAreNorthernLights = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Qu'est-ce qu'une aurore boréale ?
            </h1>
            <p>
                Les aurores boréales sont des manifestations lumineuses spectaculaires et colorées que l'on
                peut observer dans le ciel nocturne de l'hémisphère nord. Les aurores de l'hémisphère sud
                sont connues sous le nom d'aurores australes.
            </p>
            <p>
                Les aurores boréales et australes sont toutes deux des lumières polaires, ou aurora polaris,
                car elles se produisent près des pôles magnétiques de la Terre.
            </p>
            <Row>
                <Col md={9}>
                    <img src="/img/learning/colours-of-northern-lights.jpg" alt="colourful northern lights" width="100%" className="rounded" />
                    <figcaption>Aurore boréale au-dessus de Yellowknife, dans les Territoires du Nord-Ouest. (Crédits : Agence spatiale canadienne, Université de Calgary, Astronomy North)</figcaption>
                </Col>
            </Row>
            <h2 className="display-4">Comment se produisent les aurores ?</h2>
            <p>
                Les aurores se produisent lorsque des particules chargées (électrons et protons) entrent en
                collision avec les gaz de la haute atmosphère terrestre.
            </p>
            <p>
                Ces collisions produisent de minuscules éclairs qui remplissent le ciel de lumière colorée.
                Comme des milliards d'éclairs se succèdent, les aurores semblent se déplacer ou "danser"
                dans le ciel.
            </p>
            <p>
                Le champ magnétique de la Terre dirige les particules chargées vers les pôles. La forme du
                champ magnétique terrestre crée deux ovales auroraux au-dessus des pôles magnétiques nord
                et sud. C'est pourquoi les aurores se produisent presque chaque nuit dans le ciel nordique,
                d'août à mai.
            </p>
            <h2 className="display-4">Le vent solaire</h2>
            <p>
                Le champ magnétique de notre planète forme un bouclier invisible qui nous protège contre
                le vent solaire. De temps en temps, le vent solaire s'intensifie et pénètre dans le champ
                magnétique de la Terre. Le flux de particules interagit avec les gaz du champ magnétique
                (magnétosphère), ce qui produit de magnifiques aurores.
            </p>
            <p>
                En règle générale, plus le Soleil est actif :
            </p>
            <ul>
                <li>
                    plus les aurores seront éblouissantes sur Terre
                </li>
                <li>
                    plus l'ovale positionné sur le nord du Canada s'étendra vers le sud.
                </li>
            </ul>
            <p>
                Lorsque l'activité solaire diminue, l'ovale revient à sa position normale et les aurores deviennent moins intenses.
            </p>
            <Row>
                <Col md={9}>
                    <img src="/img/learning/auroral-shield.jpg" alt="auroral shield" className="pb-4 rounded" width="100%" />
                </Col>
            </Row>
            <p>
                De plus, à cause du vent solaire, il arrive que les lignes du champ magnétique se reconnectent du
                côté de la Terre opposé au Soleil. Lorsqu'elles se relâchent brusquement, tout comme un élastique,
                de grandes quantités d'énergie se déplacent vers les pôles de la Terre. Ce phénomène, appelé
                reconnexion magnétique, est à l'origine de magnifiques aurores boréales.
            </p>
            <video controls className="rounded">
                <source src="/img/auroramax_video.mp4"></source>
            </video>
            <figcaption>
                Une éjection de masse coronale (CME) est une énorme expulsion de plasma et de champ magnétique
                de l'atmosphère la plus externe du Soleil. Cette animation retrace la trajectoire d'une éjection
                de masse coronale lorsqu'elle quitte le Soleil et percute la magnétosphère de la Terre. Les lignes
                du champ magnétique se reconnectent derrière la planète et produisent les lumières polaires.
                (Crédits : ESA, NASA)
            </figcaption>
            <h2 className="display-4">Un phénomène coloré</h2>
            <p>
                Vertes, rouges, bleues : les aurores boréales se déclinent en une multitude de couleurs.
            </p>
            <p>
                Rendez-vous sur <a href="/learning/colours-of-northern-lights">la page des couleurs des aurores boréales</a> pour en savoir plus.
            </p>
            <br />
            <h3 className="display-6">Explorez en plus</h3>
            <ul>
                <li>
                    <a href="/learning/photographing-aurora">Photographier les aurores</a>
                </li>
                <li>
                    <a href="/learning/aurora-viewing-tips">Conseils pour l'observation des aurores</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container>
    );
};


export const TopicColoursOfNorthernLights = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Les couleurs des aurores boréales
            </h1>
            <p>
                Les couleurs des aurores boréales sont déterminées par un certain nombre de facteurs :
            </p>
            <ul>
                <li>la composition des gaz de l'atmosphère terrestre,</li>
                <li>l'altitude à laquelle l'aurore se produit,</li>
                <li>la densité de l'atmosphère,</li>
                <li>le niveau d'énergie impliqué.</li>
            </ul>
            <p>
                Les aurores boréales peuvent être spectaculaires !
            </p>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/colours-of-northern-lights-pink.jpg" alt="northern lights with pink hues" width="100%" className="rounded" />
                        <figcaption>
                            La frange rose vif sur le bord inférieur de l'aurore se produit lorsque des électrons frappent
                            l'azote dans l'atmosphère. (Crédits : Agence spatiale canadienne, Université de Calgary, Astronomy
                            North)
                        </figcaption>
                    </figure>
                </Col>
            </Row>
            <p>
                Les couleurs suivantes peuvent être observées lorsque les aurores boréales sont dans le ciel :
            </p>
            <p>
                <b>Vert </b>: Le vert est la couleur la plus courante vue du sol et est produit lorsque
                des particules chargées entrent en collision avec des molécules d'oxygène à des altitudes
                de 100 à 300 km.
            </p>
            <p>
                <b>Rose et rouge foncé </b>: Parfois, le bord inférieur d'une aurore présente une frange
                rose ou rouge foncé, produite par des molécules d'azote à des altitudes d'environ 100 km.
            </p>
            <p>
                <b>Rouge </b>: Un peu plus haut dans l'atmosphère (à des altitudes de 300 à 400 km), des
                collisions avec des atomes d'oxygène produisent des aurores rouges.
            </p>
            <p>
                <b>Bleu et mauve </b>: Enfin, les molécules d'hydrogène et d'hélium peuvent produire des
                aurores bleues et violettes, mais ces couleurs sont difficilement visibles pour nos yeux
                dans le ciel nocturne.
            </p>
            <br />
            <h3 className="display-6">Explorez en plus</h3>
            <ul>
                <li>
                    <a href="/learning/aurora-viewing-tips">Conseils pour l'observation des aurores</a>
                </li>
                <li>
                    <a href="/learning/what-are-northern-lights">Qu'est-ce qu'une aurore boréale ?</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container>
    );
};

export const TopicAuroraMAXObservatory = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Observatoire AuroraMAX
            </h1>
            <p>
                AuroraMAX permet d'observer les aurores boréales en haute définition, en direct depuis le Nord
                canadien, pendant la saison des aurores, entre août et mai.
            </p>
            <p>
                La caméra s'allume automatiquement dès que le soleil se couche à Yellowknife pendant la saison des
                aurores. Le compte à rebours indique le temps qu'il reste avant le début de la transmission.
            </p>
            <a href="/live" className="btn btn-outline-light btn-sm me-2 mb-2">Voir la vidéo de la nuit précédente en accéléré</a>
            <a href="/movies" className="btn btn-outline-light btn-sm">Consultez les archives vidéos</a>

            <h2 className="display-4">Pourquoi les images AuroraMAX sont-elles rondes ?</h2>
            <p>
                Imaginez que vous vous allongez sur le dos et que vous pouvez voir tout ce qui vous entoure. C'est
                ce que vous montre l'AuroraMAX.
            </p>
            <p>
                La caméra principale d'AuroraMAX est un imageur plein ciel. Elle filme un champ de vision de
                180 degrés, ce qui produit des images circulaires qui montrent l'ensemble du ciel nocturne. La
                circonférence du cercle est en fait l'horizon tout autour de la caméra. Si vous regardez
                attentivement, vous verrez même la cime des arbres dans la partie supérieure de l'image.
            </p>
            <h2 className="display-4">AuroraMAX : plus qu'une simple caméra !</h2>
            <p>
                AuroraMAX est une initiative de sensibilisation du public qui a pour but de faire connaître :
            </p>
            <ul>
                <li>les aurores boréales et leur beauté,</li>
                <li>la science derrière les aurores,</li>
                <li>l'impact de l'activité solaire sur notre vie quotidienne.</li>
            </ul>
            <p>
                Le projet AuroraMAX est dirigé par l'Université de Calgary et soutenu
                par nos partenaires, l'Agence spatiale canadienne (ASC), Astronomy North,
                et la ville de Yellowknife.
            </p>
            <p>
                Le logo d'AuroraMAX, conçu par James Pugsley d'Astronomy North, symbolise la relation
                de cause et effet entre notre étoile et notre planète. Un pic vert représentant la
                nature changeante des aurores perce le ciel sur la puissante source du vent solaire,
                le Soleil.
            </p>
            <h2 className="display-4 pt-3 pb-3">Spécifications techniques</h2>
            <ul style={{ textAlign: "left", display: "inline-block" }}>
                <li>Lieu : Yellowknife (T.N-O.)</li>
                <li>Longitude : 114°21'O</li>
                <li>Latitude : 62°26'N</li>
                <li>Appareil photo : Imageur couleur à ciel ouvert</li>
                <li>Objectif : ultra-grand angulaire (180°)</li>
                <li>Ouverture : f/4.0</li>
                <li>Durée d'exposition : 4 secondes</li>
                <li>Rafraichissement de l'image : 6 secondes</li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container>
    );
};

export const TopicAuroraViewingTips = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Conseils pour l'observation des aurores
            </h1>
            <p>
                Vous n'avez pas besoin d'équipement spécial pour observer les aurores, juste d'une bonne dose d'enthousiasme !
            </p>

            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/viewing-tips.jpg" alt="aurora display" width="100%" className="rounded" />
                        <figcaption>
                            Les communautés nordiques du Canada ont la chance de voir régulièrement le spectacle des
                            aurores boréales car elles sont situées sous l'ovale auroral. (Crédits : Agence spatiale
                            canadienne, Université de Calgary, Astronomy North)
                        </figcaption>
                    </figure>
                </Col>
            </Row>

            <h2 className="display-4">Observer les aurores boréales</h2>
            <p>
                Plus vous êtes proche du pôle Nord magnétique, plus vous avez des chances de pouvoir admirer les aurores
                boréales. Cela dit, elles peuvent également être observées dans les régions plus au sud du Canada pendant
                les périodes d'activité solaire intense.
            </p>
            <p>
                En général, les aurores apparaissent quelques heures après le coucher du soleil, lorsque le ciel est
                dégagé, et ont tendance à devenir plus intenses vers minuit. Si vous avez la chance de voir des aurores
                dans le Nord, voici comment profiter au maximum de votre nuit d'observation.
            </p>
            <h3 className="display-6">Emplacement</h3>
            <ul>
                <li>
                    Choisissez un lieu exempt de pollution lumineuse. Les lumières vives des villes qui illuminent
                    le ciel rendent invisibles les aurores de faible intensité.
                </li>
            </ul>
            <h3 className="display-6">Conditions d'observation</h3>
            <ul>
                <li>
                    Consultez les <a href="https://astronomynorth.ca/aurora-forecast/" target="blank" rel="noreferrer">prévisions
                        d'aurores boréales</a> (en anglais seulement) pour savoir s'il y aura une aurore boréale dans le ciel près de
                    chez vous.
                </li>
                <li>
                    Consultez le site de <a href="http://www.meteospatiale.gc.ca/index-fr.php" target="blank" rel="noreferrer">Météo
                        spatiale Canada</a> pour connaître les prévisions canadiennes d'activité géomagnétique, responsable des aurores
                    boréales.
                </li>
                <li>
                    Vérifiez les prévisions météorologiques locales avant de partir. Les nuages, les précipitations et la
                    pleine Lune rendent difficile l'observation des aurores.
                </li>
                <li>
                    Regardez tout autour de vous, et pas seulement vers le nord : les aurores peuvent apparaître n'importe où dans
                    le ciel.
                </li>
                <li>
                    Vérifiez la position de <a href="http://www.swpc.noaa.gov/products/aurora-30-minute-forecast" target="blank" rel="noreferrer">l'ovale
                        auroral en temps réel</a>. N'oubliez pas de vous emmitoufler - il fait froid la nuit !
                </li>
                <li>
                    N'oubliez pas d'apporter votre appareil photo si vous voulez essayer de <a href="/learning/photographing-aurora">photographier les aurores</a>.
                </li>
            </ul>
            <br />
            <h3 className="display-6">Explorez en plus</h3>
            <ul>
                <li>
                    <a href="/learning/photographing-aurora">Comment photographier une aurore boréale</a>
                </li>
                <li>
                    <a href="/learning/colours-of-northern-lights">Les couleurs des aurores boréales</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container >
    );
};

export const TopicPhotographingAurora = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Photographier les aurores
            </h1>
            <p>
                Les aurores boréales sont un phénomène de lumière spectaculaire. Si vous avez l'occasion de
                les <a href="/learning/aurora-viewing-tips">voir de vos propres yeux</a>, vous voudrez
                peut-être les capturer avec votre appareil photo.
            </p>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/photographing-aurora.jpg" alt="aurora display" width="100%" className="rounded" />
                        <figcaption>
                            Conseil de pro : prenez des photos dans des zones dégagées autant que possible, mais n'hésitez pas
                            à essayer des zones où des éléments du paysage au premier plan pourraient apporter un attrait
                            visuel à vos photos. (Crédits : Agence spatiale canadienne, Université de Calgary, Astronomy North)
                        </figcaption>
                    </figure>
                </Col>
            </Row>

            <h2 className="display-4">Matériel requis</h2>
            <p>
                Pour photographier les aurores boréales, vous aurez besoin de l'équipement suivant :
            </p>
            <ul>
                <li>
                    un appareil photo capable de faire de longues expositions (au moins 10 secondes),
                </li>
                <li>
                    un trépied solide, indispensable pour éviter les secousses,
                </li>
                <li>
                    un déclencheur à câble ou une télécommande, également pour éviter les secousses,
                </li>
                <li>
                    un objectif grand-angle (idéalement).
                </li>
            </ul>
            <h2 className="display-4">Étapes</h2>
            <p>
                Voici les trois étapes à suivre pour prendre de superbes photos d'aurores boréales.
            </p>
            <h3 className="display-6">1. Trouvez un endroit</h3>
            <ul>
                <li>
                    Choisissez un endroit sombre avec une vue dégagée sur tous les horizons.
                </li>
                <li>
                    À l'aide d'un trépied, orientez votre appareil photo de manière à ce que votre
                    image comprenne à la fois le ciel et les arbres, ou prenez une image en hauteur.
                </li>
            </ul>
            <h3 className="display-6">2. Réglez vos paramètres</h3>
            <ul>
                <li>
                    <b>Exposition : </b> pour éviter que des traînées d'étoiles n'apparaissent sur votre photo,
                    réglez votre durée d'exposition entre 10 et 25 secondes. Les expositions plus longues produisent
                    des images plus lumineuses, mais si l'aurore se déplace rapidement, l'image sera floue.
                </li>
                <li>
                    <b>Ouverture : </b> Réglez l'ouverture sur la configuration la plus basse possible
                    (f/2.8, f/3.5 ou f/5.6, en fonction de l'objectif que vous utilisez) pour faire en
                    sorte qu'un maximum de lumière entre dans votre appareil photo.
                </li>
            </ul>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/photographing-aurora2.jpg" alt="aurora" width="100%" className="rounded" />
                        <figcaption>
                            Photo d'aurores boréales prise à Yellowknife, dans les Territoires du Nord-Ouest.
                            (Crédits : Agence spatiale canadienne, Université de Calgary, Astronomy North)
                        </figcaption>
                    </figure>
                </Col>
            </Row>
            <ul>
                <li>
                    <b>ISO :</b> réglez la vitesse de votre film sur 400 ISO ou 800 ISO pour enregistrer la lumière
                    avec suffisamment de détail. Les vitesses de film plus élevées permettent de recueillir plus de
                    lumière et de couleurs, mais la qualité de l'image est souvent granuleuse.
                </li>
                <li>
                    <b>Mise au point :</b> Mettez au point votre appareil photo sur les étoiles. Réglez votre objectif
                    sur ou près de l'infini pour que les étoiles soient mises au point. Si vous utilisez un appareil
                    photo numérique, prenez une photo d'entraînement des étoiles et revoyez l'image pour vérifier la
                    mise au point.
                </li>
            </ul>
            <h3 className="display-6">3. Commencez à prendre des photos</h3>
            <p>
                Expérimentez différentes combinaisons de vitesse de film et de durée d'exposition. Le clair de lune
                et les aurores boréales peuvent modifier rapidement les conditions d'éclairage, alors soyez prêt à
                ajuster ces paramètres à tout moment.
            </p>
            <p>Amusez-vous bien !</p>
            <br />
            <h3 className="display-6">Explorez en plus</h3>
            <ul>
                <li>
                    <a href="/learning/what-are-northern-lights">Qu'est-ce qu'une aurore boréale ?</a>
                </li>
                <li>
                    <a href="/learning/aurora-viewing-tips">Comment observer une aurore boréale</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container>
    );
};

export const TopicStudyingAurora = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Étudier les aurores au Canada
            </h1>
            <p>
                Le pôle Nord magnétique se trouve dans l'Arctique canadien, une situation géographique qui offre aux
                scientifiques canadiens un point de vue privilégié pour étudier les aurores.
            </p>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/studying-aurora.jpg" alt="aurora display" width="100%" className="rounded" />
                        <figcaption>Sources : Agence spatiale canadienne, Université de Calgary, Astronomy North.</figcaption>
                    </figure>
                </Col>
            </Row>
            <p>
                L'étude de la relation entre la Terre et le Soleil aide les scientifiques à prévoir les
                périodes d'activité solaire intense afin de se préparer à la météo spatiale.
            </p>
            <p>
                Les scientifiques commencent seulement aujourd'hui à comprendre les phénomènes liés au
                champ magnétique de la Terre. Les recherches menées par des Canadiens permettent de mieux
                comprendre la magnétosphère, qui nous protège des radiations mortelles du Soleil.
            </p>
            <p>Voici quelques exemples de recherches canadiennes en cours :</p>
            <Row className="pb-3">
                <Col xs={12} md={4}>
                    <a href="https://www.asc-csa.gc.ca/fra/satellites/cassiope.asp" target="blank" rel="noreferrer" ><b>ePOP</b></a>
                    <p>
                        Suite d'instruments scientifiques embarqués sur le satellite CASSIOPE pour observer l'ionosphère.
                    </p>
                </Col>
                <Col xs={12} md={4}>
                    <a href="https://www.asc-csa.gc.ca/fra/satellites/themis/" target="blank" rel="noreferrer"><b>THEMIS</b></a>
                    <p>
                        Vingt observatoires au sol et cinq petits satellites transportant des détecteurs électriques,
                        magnétiques et de particules pour étudier les aurores.
                    </p>
                </Col>
                <Col xs={12} md={4}>
                    <a href="https://www.asc-csa.gc.ca/fra/sciences/meteo-spatiale.asp" target="blank" rel="noreferrer"><b>La météo spatiale au Canada</b></a>
                    <p>
                        Les scientifiques canadiens conçoivent et exploitent des instruments pour améliorer
                        la compréhension des causes et des processus de la météo spatiale.
                    </p>
                </Col>
            </Row>
            <p>
                En savoir plus sur l'histoire des études Soleil-Terre au Canada :
            </p>
            <ul>
                <li>
                    <a href="https://www.asc-csa.gc.ca/fra/sciences/observatoire-magnetique.asp" target="blank" rel="noreferrer">Le premier observatoire magnétique du Canada</a>
                </li>
                <li>
                    <a href="https://www.asc-csa.gc.ca/fra/satellites/alouette.asp" target="blank" rel="noreferrer">Satellites Alouette I et II</a>
                </li>
                <li>
                    <a href="https://www.asc-csa.gc.ca/fra/satellites/isis.asp" target="blank" rel="noreferrer">Satellites ISIS I et II</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container>
    );
};

export const TopicAuroraMAXSimulator = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
            <h1 className="display-2 pb-3">
                Simulateur d'aurores boréales AuroraMAX
            </h1>
            <p>
                Le simulateur d'aurores boréales AuroraMAX se trouve
                au <a href="http://www.pwnhc.ca/" target="blank" rel="noreferrer">Centre du patrimoine septentrional Prince-de-Galles</a> à
                Yellowknife, dans les Territoires du Nord-Ouest.
            </p>
            <p>
                Cette exposition interactive vous permet d'apprendre sur un grand écran :
            </p>
            <ul>
                <li>
                    comment les aurores boréales se forment dans la haute atmosphère de la Terre,
                </li>
                <li>
                    comment on peut les observer sur Terre,
                </li>
                <li>
                    les effets de l'activité solaire sur la Terre.
                </li>
            </ul>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/auroramax-simulator.jpg" alt="" width="100%" className="rounded" />
                        <figcaption>
                            Une personne interagissant avec le simulateur d'aurores boréales AuroraMAX
                            de l'Agence spatiale canadienne. (Crédit : Agence spatiale canadienne)
                        </figcaption>
                    </figure>
                </Col>
            </Row>
            <h2 className="display-4">Une expérience interactive</h2>
            <p>
                Un système sans contact perçoit les mouvements de la main, permettant à l'utilisateur
                d'interagir directement avec le simulateur afin de :
            </p>
            <ul>
                <li>
                    visiter les planètes du système solaire,
                </li>
                <li>
                    apprendre davantage sur les phénomènes qui produisent les aurores,
                </li>
                <li>
                    mieux comprendre les effets de l'activité solaire sur la Terre.
                </li>
            </ul>
            <p>
                Vous pouvez également :
            </p>
            <ul>
                <li>
                    dessiner votre propre aurore et l'envoyer par courriel sous forme de carte postale virtuelle,
                </li>
                <li>
                    voir des images en direct du Soleil,
                </li>
                <li>
                    explorer une galerie d'images et de vidéos.
                </li>
            </ul>
            <h2 className="display-4">Admirer les aurores sans avoir à se déplacer</h2>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/aurora.jpg" alt="aurora" width="100%" className="rounded" />
                        <figcaption>Credits : Canadian Space Agency (CSA), University of Calgary, Astronomy North</figcaption>
                    </figure>
                </Col>
            </Row>
            <p>
                Si vous ne pouvez pas vous rendre dans le Nord canadien, vous avez deux possibilités pour admirer les aurores :
            </p>
            <ul>
                <li>
                    Vous pouvez voir les aurores boréales en direct de Yellowknife sur votre ordinateur grâce
                    à <a href="/live">l'observatoire virtuel AuroraMAX</a>.
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Retour au Centre d'apprentissage</a>
            </div>
        </Container>
    );
};