import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCamera, faClapperboardPlay, faGraduationCap, faInfoCircle, faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import NavDropdown from 'react-bootstrap/NavDropdown';

import "../styles/nav.scss";

const AuroraMAXNav = ({ homepage }) => {
    const [navBackground, setNavBackground] = useState(false);
    const navRef = useRef();
    const location = useLocation();
    navRef.current = navBackground;

    useEffect(() => {
        const handleScroll = () => {
            if (homepage) {
                const show = window.scrollY > 20;
                if (navRef.current !== show) {
                    setNavBackground(show);
                }
            }
        };
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [homepage]);

    return (
        <Navbar variant="dark" bg="black" expand="lg" fixed="top" style={{ borderBottom: "1px solid rgb(255,255,255,0.25)" }}>
            <Container fluid>
                <Navbar.Brand href="/" style={{ margin: "0px", paddingBottom: "5px" }}>
                    <img src="/img/logos/auroramax_logo.svg" alt="AuroraMAX logo" style={{ height: "45px" }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav style={{ marginLeft: "auto" }} activeKey={location.pathname}>
                        <Nav.Link href="/live">
                            <FontAwesomeIcon icon={faCamera} fixedWidth style={{ paddingRight: "5px" }} />
                            Vidéo en direct
                        </Nav.Link>
                        <Nav.Link href="/movies">
                            <FontAwesomeIcon icon={faClapperboardPlay} fixedWidth style={{ paddingRight: "5px" }} />
                            Vidéos
                        </Nav.Link>
                        <Nav.Link href="/learning" className={location.pathname.startsWith("/learning") ? "active" : null}>
                            <FontAwesomeIcon icon={faGraduationCap} fixedWidth style={{ paddingRight: "5px" }} />
                            Centre d'apprentissage
                        </Nav.Link>
                        <Nav.Link href="/about">
                            <FontAwesomeIcon icon={faInfoCircle} fixedWidth style={{ paddingRight: "5px" }} />
                            À propos de nous
                        </Nav.Link>
                        <Nav.Link href="/contact">
                            <FontAwesomeIcon icon={faCircleQuestion} fixedWidth style={{ paddingRight: "5px" }} />
                            Contactez-nous
                        </Nav.Link>
                        <Nav.Link href="https://twitter.com/AuroraMAX_asc" target="blank">
                            <FontAwesomeIcon icon={faTwitter} fixedWidth style={{ paddingRight: "5px" }} />
                            <span className="d-lg-none" style={{ display: "inline-block" }}>Twitter</span>
                        </Nav.Link>
                        <Nav.Link href="https://www.facebook.com/AuroraMAXasc" target="blank">
                            <FontAwesomeIcon icon={faFacebook} fixedWidth style={{ paddingRight: "5px" }} />
                            <span className="d-lg-none" style={{ display: "inline-block" }}>Facebook</span>
                        </Nav.Link>
                        <NavDropdown title="FR" align="end">
                            <NavDropdown.Item href="https://auroramax.com">
                                Anglais
                            </NavDropdown.Item>
                            <NavDropdown.Item className="active">
                                Français
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default React.memo(AuroraMAXNav);