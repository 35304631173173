import React, { useEffect } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-light-svg-icons';
import { faClapperboardPlay } from '@fortawesome/pro-light-svg-icons';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

import '../styles/homepage.scss';

const Homepage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0';
    script.crossOrigin = 'anonymous';
    script.defer = true;
    script.async = true;
    script.nonce = 'fSjNszYW';
    script.id = 'facebook-sdk-script';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container fluid className='m-0 p-0 w-100 container-homepage'>
      <Row
        className='homepage-banner align-items-center m-0'
        style={{
          minHeight: '90vh',
          backgroundImage: `url("/img/banner2.jpg")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col>
              {/* <h1>AuroraMAX</h1> */}
              <img
                src='/img/logos/auroramax_logo.svg'
                alt='AuroraMAX logo'
                id='banner-logo'
              />
            </Col>
          </Row>
          <Row className='d-flex pt-4 pb-4'>
            <Col>
              <div style={{ margin: 'auto' }}>
                <h2 className='tagline'>
                  La science dans toute sa splendour !
                </h2>
              </div>
            </Col>
          </Row>
          <Container>
            <Row className='pt-5 pb-5 justify-content-center'>
              <Col>
                <a href='/live'>
                  <Button
                    size='lg'
                    variant='outline-light'
                    className='mx-3 mb-3'
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      fixedWidth
                      style={{ marginRight: '10px' }}
                    />
                    Regarder la vidéo en direct
                  </Button>
                </a>
                <a href='/movies'>
                  <Button
                    size='lg'
                    variant='outline-light'
                    className='mx-3 mb-3'
                  >
                    <FontAwesomeIcon
                      icon={faClapperboardPlay}
                      fixedWidth
                      style={{ marginRight: '10px' }}
                    />
                    Regarder les vidéos des nuits précédentes
                  </Button>
                </a>
                <a href='/learning'>
                  <Button
                    size='lg'
                    variant='outline-light'
                    className='mx-3 mb-3'
                  >
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      fixedWidth
                      style={{ marginRight: '10px' }}
                    />
                    Centre d'apprentissage
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
      </Row>
      <Row className='justify-content-center gx-0 homepage-section pb-4 px-5'>
        <Col className='pt-5 pb-3 col-xs-12 col-sm-12 col-md-10 col-xl-8 col-xxl-7'>
          <h1 className='display-4 pb-3 white-text'>
            Qu'est-ce qu'AuroraMAX ?
          </h1>
          <p className='white-text'>
            AuroraMAX est un observatoire canadien d'aurores boréales. Grâce à
            une <strong>caméra au sol</strong> située à Yellowknife, dans les
            Territoires du Nord-Ouest, il permet de regarder des aurores dans le
            confort de votre foyer. La caméra s'allume automatiquement{' '}
            <strong>dès le coucher du Soleil</strong> à Yellowknife pendant la
            saison des aurores boréales. Le compte à rebours indique le temps
            qu'il reste avant la mise en marche de la caméra. AuroraMAX est un
            observatoire canadien des aurores. Grâce à une{' '}
            <strong>caméra au sol</strong> située à Yellowknife, dans les
            Territoires du Nord-Ouest, vous pouvez observer les aurores dans le
            confort de votre maison. La caméra s'allume automatiquement dès que
            le soleil se couche à Yellowknife pendant la saison des aurores. Le
            compte à rebours indique le temps qu'il reste avant le début de la
            transmission.
          </p>
          <p className='white-text'>
            En plus de la caméra, AuroraMAX est une initiative de
            sensibilisation du public qui vise à faire connaître :
            <ul>
              <li>les aurores boréales et leur beauté,</li>
              <li>la science derrière les aurores,</li>
              <li>
                ainsi que l'impact de l'activité solaire sur notre vie
                quotidienne
              </li>
            </ul>
          </p>
          <p className='white-text'>
            Le projet AuroraMAX est dirigé par l'Université de Calgary et
            soutenu par nos partenaires, l'Agence spatiale canadienne (ASC),
            Astronomy North, et la ville de Yellowknife.
          </p>
          <p className='white-text'>
            Le logo d'AuroraMAX, conçu par James Pugsley d'Astronomy North,
            symbolise la relation de cause à effet entre notre étoile (le
            Soleil) et notre planète. Un pic vert représentant la nature
            changeante des aurores perce le ciel sur la puissante source du vent
            solaire, le Soleil.
          </p>
        </Col>
      </Row>

      {/* Live feed section */}
      <Row className='row justify-content-center py-5 px-4 homepage-section-alt-bg gx-0'>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            Vidéo en direct
          </h3>
          <p className='white-text'>
            AuroraMAX permet d'observer les aurores boréales dans le confort de
            votre maison. L'image en temps réel est diffusée en direct depuis le
            Nord canadien pendant la saison des aurores (entre août et mai).
          </p>
          <p>
            <a href='/live' className='custom-colored-link'>
              <b id='link'>Regardez la vidéo en direct →</b>
            </a>
          </p>
        </div>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <a href='/live'>
            <img
              alt='Latest live feed'
              src='/img/homepage_livefeed1.png'
              className='img-thumbnail img-responsive img-no-border'
            />
          </a>
        </div>
      </Row>

      {/* Movies section */}
      <Row className='justify-content-center py-5 px-4 gx-0 homepage-section'>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <a href='/movies'>
            <img
              alt='Movie browser screenshot'
              src='/img/homepage_movies1.png'
              className='img-thumbnail img-responsive'
            />
          </a>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            Vidéos des nuits précédentes
          </h3>
          <p className='white-text'>
            Chaque matin, une vidéo des images diffusées au cours de la nuit
            précédente est générée afin que vous puissiez voir rapidement
            comment la nuit s'est passée. Vous pouvez parcourir toutes les
            vidéos générées par le projet AuroraMAX depuis 2010.
          </p>
          <p>
            <a href='/movies' className='custom-colored-link'>
              <b>Parcourir les vidéos →</b>
            </a>
          </p>
        </div>
      </Row>

      {/* Learning section */}
      <Row className='justify-content-center py-5 px-4 homepage-section-alt-bg gx-0'>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            Apprenez-en plus sur les aurores
          </h3>
          <p className='white-text'>
            Dans le centre d'apprentissage AuroraMAX, vous pourrez en apprendre
            davantage sur les aurores et la science qui les entoure, ainsi
            qu'obtenir des conseils pour les observer et les photographier
            vous-même.
          </p>
          <p>
            <a href='/learning' className='custom-colored-link'>
              <b>Consultez le centre d'apprentissage →</b>
            </a>
          </p>
        </div>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <a href='/learning'>
            <img
              alt='Learning center screenshot'
              src='/img/aurora_borealis_photo.jpg'
              className='img-thumbnail img-responsive'
            />
          </a>
        </div>
      </Row>

      {/* Social media section */}
      <Row className='justify-content-center py-5 px-4 gx-0 homepage-section'>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <TwitterTimelineEmbed
            sourceType='timeline'
            screenName='AuroraMAX_asc'
            tweetLimit='1'
            theme='dark'
            options={{ height: 400 }}
          />
        </div>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            AuroraMAX sur les médias sociaux
          </h3>
          <p className='white-text'>
            Restez au courant des activités d'AuroraMAX sur Twitter et Facebook.
            Nous publions chaque fois que des tempêtes solaires sont prévues,
            afin que vous puissiez savoir quand vous connecter à la
            transmission.
          </p>
          <a
            className='btn btn-outline-light btn-sm me-3 mb-2'
            target='_blank'
            rel='noreferrer'
            href='https://twitter.com/auroramax_asc'
          >
            Voir la page Twitter
            <FontAwesomeIcon
              icon={faTwitter}
              fixedWidth
              style={{ marginLeft: '5px' }}
            />
          </a>
          <a
            className='btn btn-outline-light btn-sm mb-2'
            target='_blank'
            rel='noreferrer'
            href='https://www.facebook.com/AuroraMAXasc'
          >
            Voir la page Facebook
            <FontAwesomeIcon
              icon={faFacebook}
              fixedWidth
              style={{ marginLeft: '5px' }}
            />
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default Homepage;
