import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import AuroraMAXNav from "./components/Nav";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Homepage from "./pages/Homepage";
import Learning, { LearningTopics } from "./pages/learning/Learning";
import {
    TopicAuroraMAXObservatory,
    TopicAuroraMAXSimulator,
    TopicAuroraViewingTips,
    TopicColoursOfNorthernLights,
    TopicPhotographingAurora,
    TopicStudyingAurora,
    TopicWhatAreNorthernLights
} from "./pages/learning/Topics";
import LiveFeed from "./pages/LiveFeed";
import Movies from "./pages/Movies";

import { useGoogleAnalytics } from "./analytics";

const withNavFooter = (pageContent, homepage = false) => {
    return (
        <>
            <Container fluid className="p-0" style={{ flexDirection: "column" }}>
                <Row className="p-0 m-0">
                    <Col xs={12} className="p-0">
                        <AuroraMAXNav homepage={homepage} />
                    </Col>

                    <Col xs={12} className="p-0" style={homepage ? { marginTop: 50 } : { marginTop: 100 }}>
                        {pageContent}
                    </Col>
                </Row>

                <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                        <Footer />
                    </Col>
                </Row>
            </Container>

        </>
    )
};

const AuroraMAXRoutes = () => {
    useGoogleAnalytics();

    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    withNavFooter(<Homepage />, true)
                }
            />
            <Route
                exact
                path="/live"
                element={
                    withNavFooter(<LiveFeed />, false)
                }
            />
            <Route
                exact
                path="/movies"
                element={
                    withNavFooter(<Movies />, false)
                }
            />
            <Route
                path="/learning"
                element={withNavFooter(<Learning />, false)}
            >
                <Route index element={<LearningTopics />} />
                <Route path="what-are-northern-lights" element={<TopicWhatAreNorthernLights />} />
                <Route path="colours-of-northern-lights" element={<TopicColoursOfNorthernLights />} />
                <Route path="auroramax-observatory" element={<TopicAuroraMAXObservatory />} />
                <Route path="aurora-viewing-tips" element={<TopicAuroraViewingTips />} />
                <Route path="photographing-aurora" element={<TopicPhotographingAurora />} />
                <Route path="studying-aurora-in-canada" element={<TopicStudyingAurora />} />
                <Route path="auroramax-simulator" element={<TopicAuroraMAXSimulator />} />
            </Route>
            <Route
                exact
                path="/about"
                element={
                    withNavFooter(<About />, false)
                }
            />
            <Route
                exact
                path="/contact"
                element={
                    withNavFooter(<ContactUs />, false)
                }
            />
        </Routes>
    )
};

export default AuroraMAXRoutes;