import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
    return (
        <Container fluid className="white-text pb-5 px-5">
            <h1 className="pb-3 display-1 text-center">About AuroraMAX</h1>
            <div className="row justify-content-center pb-3">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-9 col-xxl-8">
                    <p className="white-text">
                        AuroraMAX est un observatoire canadien d'aurores boréales. Grâce à
                        une <strong>caméra au sol</strong> située à Yellowknife, dans les
                        Territoires du Nord-Ouest, il permet de regarder des aurores dans
                        le confort de votre foyer. La caméra s'allume automatiquement <strong>dès
                            le coucher du Soleil</strong> à Yellowknife pendant la saison des
                        aurores boréales. Le compte à rebours indique le temps qu'il reste avant
                        la mise en marche de la caméra.

                        AuroraMAX est un observatoire canadien des aurores. Grâce à
                        une <strong>caméra au sol</strong> située à Yellowknife, dans les
                        Territoires du Nord-Ouest, vous pouvez observer les aurores dans le
                        confort de votre maison. La caméra s'allume automatiquement dès que
                        le soleil se couche à Yellowknife pendant la saison des aurores. Le
                        compte à rebours indique le temps qu'il reste avant le début de la
                        transmission.
                    </p>
                    <p className="white-text">
                        En plus de la caméra, AuroraMAX est une initiative de sensibilisation du
                        public qui vise à faire connaître :

                        <ul>
                            <li>les aurores boréales et leur beauté,</li>
                            <li>la science derrière les aurores,</li>
                            <li>ainsi que l'impact de l'activité solaire sur notre vie quotidienne</li>
                        </ul>
                    </p>
                    <p className="white-text">
                        Le projet AuroraMAX est dirigé par l'Université de Calgary et soutenu
                        par nos partenaires, l'Agence spatiale canadienne (ASC), Astronomy North,
                        et la ville de Yellowknife.
                    </p>
                    <p className="white-text">
                        Le logo d'AuroraMAX, conçu par James Pugsley d'Astronomy North, symbolise
                        la relation de cause à effet entre notre étoile (le Soleil) et notre planète.
                        Un pic vert représentant la nature changeante des aurores perce le ciel sur la
                        puissante source du vent solaire, le Soleil.
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default About;
